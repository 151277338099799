<template>
  <el-container style="margin-top: 40px;height: calc(100% - 45px)">
    <TalkView :tokenkey="tokenkey " :customerId="customerId"></TalkView>
  </el-container>
</template>
<script>
  import TalkView from './components/TalkView'
  export default {
    name: "index", metaInfo: {
      title: '杭州思软客服系统',
      meta: [
        {charset: 'utf-8'},
        {name: 'viewport', content: 'width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no'}
      ]
    },
    components: {
      TalkView,
    },
    data() {
      return {
        tokenkey: this.$route.query.tokenkey,
        customerId: this.$route.query.s
      }
    },
  }
</script>

<style scoped lang="scss">

</style>
